<template>
  <div class="chatCards" :class="{ chatCards__active: active }">
    <div
      class="chatCards__notification"
      :class="{
        chatCards__notificationActive:
          chatCardInformation.messages[0] &&
          !chatCardInformation.messages[0].is_read &&
          recipient_id !== chatCardInformation.messages[0].recipient_id,
      }"
    ></div>
    <div class="d-flex justify-content-center align-items-center">
      <avatar
        :image-name="chatCardInformation.image"
        :user-code="chatCardInformation.code"
        :user-role="userInformation.role_id === 4 ? 'experts' : 'employees'"
        size="100"
        width="40px"
        height="40px"
      />
      <div class="chatCards__content">
        <div class="chatCards__name">
          <short-name :name="chatCardInformation.name" />
        </div>
        <div class="chatCards__message">
          {{ chatCardInformation.position }}
        </div>
      </div>
    </div>
    <div class="chatCards__data">
      <!--      <full-data :data="chatCardInformation.date"/>-->
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/UI/images/Avatar";
import ShortName from "@/components/components-helpers/ShortName";
import FullData from "@/components/components-helpers/FullData";
import { mapGetters } from "vuex";

export default {
  props: {
    chatCardInformation: Object,
    active: Boolean,
    notification: Boolean,
  },
  name: "ChatCard",
  components: { FullData, ShortName, Avatar },
  computed: {
    recipient_id() {
      return this.userInformation.role_id === 4 ? 2 : 1;
    },
    ...mapGetters({
      userInformation: "CURRENT_USER_INFORMATION",
    }),
  },
};
</script>

<style></style>
