<template>
  <main-layout>
    <div class="row notificationPage__title">
      <h1>{{ $t("notificationsPageTitle") }}</h1>
    </div>
    <div v-if="chats.length > 0" class="notificationPage__container">
      <div class="notificationPage__experts">
        <div class="notificationPage__expertsTitle">
          {{ $t("sidebarExpertsBtn") }}:
        </div>
        <search-input
          v-model="search"
          :search-function="searchChat"
          @keyup.enter="searchChat"
          :placeholder="$t('notificationsPageSearchInput')"
        />
        <div class="notificationPage__expertsContainer">
          <chat-card
            v-for="chat in chats"
            :key="chat.id"
            :chat-card-information="chat"
            @click="selectChat(chat)"
            :active="activeChat[chat.id]"
          />
        </div>
      </div>
      <div class="notificationPage__chatBlock">
        <div
          v-if="!!activeChatId"
          class="row flex-row align-items-center notificationPage__topBlock"
        >
          <avatar
            class="w-auto"
            :image-name="chatInformation.image"
            :user-code="chatInformation.code"
            :user-role="userInformation.role_id === 4 ? 'experts' : 'employees'"
            size="100"
            width="40px"
            height="40px"
          />
          <div class="notificationPage__chatBlock-currentUser">
            <short-name :name="chatInformation.name" />
          </div>
        </div>
        <div id="scrollable" class="notificationPage__chatScroll">
          <chat-message
            v-for="activeChatMessage in chatInformation.messages"
            :key="activeChatMessage.id"
            :id="'t' + activeChatMessage.id"
            :upcoming="
              activeChatMessage.recipient_id === chatInformation.recipient_id
            "
            :chat-message="activeChatMessage"
            :filePath="chatInformation.file_upload_dir"
          />
        </div>
        <div v-if="!!activeChatId" class="notificationPage__inputs">
          <div class="notificationPage__fileContainer">
            <div
              v-for="(file, i) in files"
              :key="file.name"
              class="notificationPage__fileItem"
            >
              <div class="notificationPage__fileName">{{ file.name }}</div>
              <div
                @click="deleteFile(i)"
                class="notificationPage__fileRemoveIcon"
              >
                <remove-file-icon />
              </div>
            </div>
          </div>
          <label for="files">
            <add-file-icon />
          </label>
          <input
            type="file"
            name="files"
            id="files"
            ref="files"
            v-on:change="handleFileUpload()"
            class="d-none"
            multiple
          />
          <input
            :placeholder="$t('notificationsPageMessageInput')"
            @keyup.enter="sendMessage(message)"
            v-model="message"
            type="text"
          />
          <div
            class="notificationPage__inputs-arrow"
            @click="sendMessage(message)"
          >
            <horizontal-arrow />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="cartPage__noItems">
      <h4>{{ $t("dontHaveActiveChat") }}</h4>
      <div>
        <p>{{ $t("noHaveActiveChatTitle") }}</p>
        <p v-if="userInformation.role_id === 4">
          {{ $t("goTo") }}
          <span class="weight-600">
            {{ $t("headerCalendarBtn") }}
          </span>
          {{ $t("toBook") }}
        </p>
      </div>
      <div v-if="userInformation.role_id === 4">
        <base-button link="/calendar" :calendar="true">
          <template #icon>
            <calendar-icon />
          </template>
          {{ $t("headerCalendarBtn") }}
        </base-button>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import SearchInput from "@/components/UI/inputs/SearchInput";
import ChatCard from "@/components/elements/card/ChatCard";
import Avatar from "@/components/UI/images/Avatar";
import ChatMessage from "@/components/elements/card/ChatMessage";
import AddFileIcon from "@/components/UI/icons/AddFileIcon";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import FullData from "@/components/components-helpers/FullData";
import ShortName from "@/components/components-helpers/ShortName";
import RemoveFileIcon from "@/components/UI/icons/addRemoveIcons/RemoveFileIcon";
import { mapGetters } from "vuex";
import BaseButton from "@/components/UI/buttons/BaseButton";
import CalendarIcon from "@/components/UI/icons/CalendarIcon";

export default {
  name: "NotificationPage",
  components: {
    CalendarIcon,
    BaseButton,
    RemoveFileIcon,
    ShortName,
    FullData,
    MainLayout,
    SearchInput,
    ChatCard,
    Avatar,
    ChatMessage,
    AddFileIcon,
    HorizontalArrow,
  },
  data() {
    return {
      search: null,
      activeChat: [],
      activeChatId: null,
      files: "",
      filesName: [],
      message: "",
    };
  },
  mounted() {
    let openChat = { id: this.$store.getters.OPEN_CHAT };
    this.$store.commit("SET_CHAT_MESSAGES", []);
    if (!!openChat.id) this.selectChat(openChat);
  },
  methods: {
    searchChat() {
      this.$http
        .get("/chats", {
          params: {
            search: this.search,
          },
        })
        .then(({ data }) => {
          this.$store.commit("SET_CHATS", data.body);
        });
    },
    handleFileUpload() {
      this.files = this.$refs.files.files;
      this.uploadFiles(this.files);
    },
    deleteFile(index) {
      let file = [];
      for (let i in this.files) {
        if (typeof this.files[i] === "object") {
          file.push(this.files[i]);
        }
      }
      file = file.filter((item, i) => i !== index);
      this.files = file;
    },
    sendMessage(text) {
      if (text.length > 0 || this.filesName.length > 0) {
        this.$http
          .post("messages", {
            chat_id: this.chatInformation.id,
            recipient_id: this.chatInformation.recipient_id,
            text: text,
            files: this.filesName,
          })
          .then(() => {
            this.showChat();
            this.message = "";
            this.files = [];
            this.filesName = [];
          });
      }
    },
    uploadFiles(file) {
      for (let i = 0; i < file.length; i++) {
        let formData = new FormData();
        formData.append("file", file[i]);
        this.$http
          .post("uploads/file", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((data) => {
            this.filesName.push(data.data.body);
          });
      }
    },
    selectChat(chat) {
      this.activeChat = [];
      this.activeChat[chat.id] = true;
      this.activeChatId = chat;
      this.showChat();
    },
    showChat() {
      this.$http.get("chats/" + this.activeChatId.id).then(({ data }) => {
        let chatInformation = data.body;
        chatInformation.name = this.activeChatId.name;
        chatInformation.code = this.activeChatId.code;
        chatInformation.image = this.activeChatId.image;
        chatInformation.recipient_id =
          this.userInformation.role_id === 4 ? 2 : 1;
        this.$store.commit("SET_CHAT_MESSAGES", chatInformation);
        this.scrollBottom();
      });
    },
    scrollBottom() {
      let lastItem = this.chatInformation.messages.at(-1);
      if (lastItem) {
        let itemId = "t" + lastItem.id;
        setTimeout(() => {
          let element = document.getElementById(itemId);
          element.scrollIntoView({ behavior: "smooth" });
        }, 500);
        this.$socket.emit("read-message", {
          chatId: this.chatInformation.id,
          recipientId: this.chatInformation.recipient_id,
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      chats: "CHATS",
      userInformation: "CURRENT_USER_INFORMATION",
      chatInformation: "CHAT_MESSAGES",
    }),
  },
};
</script>

<style></style>
